<template>
  <div class="warn-detail-wrap">
    <div class="content-wrapper">
      <div class="card-box">
        <h4 class="title">{{detail.topic}}</h4>
        <p class="label"><span>{{detail.createGroupName}}</span><span>{{detail.publishTime}}</span></p>
        <p class="content">{{detail.content}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
const { getAlarmDetail, addReadRecord } = API

export default {
  name: 'warnDetail',
  data () {
    return {
      detail: {}
    }
  },
  created () {
    this.getAlarmDetail()
  },
  methods: {
    // 获取预警详情
    async getAlarmDetail () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let alarmId = this.$route.params.id
      addReadRecord({ alarmId })
      let res = await getAlarmDetail({ alarmId })

      loading.clear()

      if (res.success) {
        this.detail = res.data
      } else {
        this.$toast(res.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-wrapper{
    .card-box{
      width: 100%;
      height: 100%;
      color: #333;
      overflow: auto;
      font-size: 28px;
      .title{
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 28px;
      }
      .label{
        color: #999;
        font-size: 24px;
        margin-bottom: 34px;
        span:first-child{
          margin-right: 24px;
        }
      }
    }
  }
</style>
